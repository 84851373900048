/*-------------------------------------
        Master-detail
-------------------------------------*/

.ReactTable {
  &.editable-data-grid {
    .rt-table {
      .rt-thead {
        &.-header {
          &.-header {
            text-align: left;
            font-size: 11px;
            font-weight: 400;
            line-height: 14px;
            color: var(--darkest-grey);
            padding: 0 1rem;
            box-shadow: none;
          }

          .rt-th {
            border-right: none;
            text-align: left;
            font-size: var(--font-th);
            font-weight: 400;
          }
        }
      }
    }
  }

  &.master-detail {
    min-width: calc(100% + 2.5rem);
    margin: 0 -1.25rem;
    background-color: var(--white);
    cursor: pointer;
    border: none;

    .selected {
      background-color: var(--primary-selected-masterdetail) !important;
    }

    .hasError {
      background-color: rgba(214, 25, 25, 0.2);
    }

    .rt-resizer {
      display: none;
    }

    .rt-table {
      .rt-thead {
        &.-header {
          text-align: left;
          font-size: 11px;
          font-weight: 400;
          line-height: 14px;
          color: var(--darkest-grey);
          box-shadow: none;
          cursor: default;
        }

        .rt-th {
          border-right: none;
          font-size: var(--font-th);
          text-align: left;
          font-weight: 400;
        }
      }

      .rt-tbody {
        color: var(--text-color);
        line-height: 26px;
        font-size: 15px;
        border-left: none;
        border-right: none;
        border-top: 1px solid var(--border-color-normal);
        border-bottom: 1px solid var(--border-color-normal);
        overflow: hidden;

        .rt-tr-group {
          border-bottom: 1px solid var(--border-color-normal);

          &:last-child {
            border-bottom: none;
          }
        }

        .rt-td {
          height: 25px;
          padding: 0 5px;
          border-right: none;

          &:last-child {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .btn-outline-secondary {
            margin-top: -1px;
          }
        }
      }
    }
  }
}

/* Master-detail only in Modal */
.modal-body {
  .master-detail {
    tbody {
      tr {
        &:last-child {
          border-bottom: 1px solid var(--dark-grey);
        }
        td {
          border-top: 1px solid var(--dark-grey);

          &:first-child {
            padding: 0 1.6rem;
          }
        }
      }
    }
  }
}

.card-adviesbox {
  .master-detail {
    min-width: calc(100% + 1.25rem);
    margin: 0 -0.6rem 0 -0.65rem;
    background-color: var(--white);
    cursor: pointer;

    .selected {
      background-color: var(--primary-selected-masterdetail);
    }

    thead {
      font-size: 11px;
      font-weight: bold;
      line-height: 14px;
      th {
        &:first-child {
          color: var(--darkest-grey);
          padding: 0 1.5rem;
        }
        &:last-child {
          width: 5.2rem;
        }
      }
    }

    tbody {
      color: var(--text-color);
      line-height: 26px;
      font-size: 13px;
      tr {
        &:last-child {
          border-bottom: 1px solid var(--dark-grey);
        }
        td {
          border-top: 1px solid var(--dark-grey);
          min-width: 68px;

          &:first-child {
            padding: 0 1.6rem;
          }

          &:last-child {
            min-width: initial;
          }
        }
      }
    }
  }
}
