.clickable_label_fix {
  height: 100%;
  margin-top: -5px;

  > div {
    height: 100%;

    label {
      height: 100%;
    }
  }
}

.hypotheek_opties_button {
  > i::before {
    color: var(--black);
  }
}

.modal_footer {
  background: var(--light-grey);
  padding: 10px 1.25rem;
  border-top: none;
}

.align_center {
  display: flex;
  justify-content: center;
}

.text_container {
  h2 {
    padding: 0;
  }
}
