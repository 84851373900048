.backdrop {
  &-1 {
    z-index: 1051 !important;
  }
}

.dialog {
  &-1 {
    z-index: 1052 !important;
  }
}
