.checkbox {
  margin: 5px;
  display: inline-flex;

  input[type="checkbox"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    + .checkbox_label {
      position: relative;
      margin-bottom: 0;
      vertical-align: top;
      cursor: pointer;
      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 11px;
        height: 11px;
        top: 0.55rem;
        left: 0rem;
        pointer-events: none;
        background-color: var(--white);
        border: 1px solid var(--checkbox-border);
        transition: all 100ms ease-in-out;
      }
      span {
        display: inline-block;
        padding: 0.3rem 0 0 1rem;
      }
    }
    &:checked {
      + .checkbox_label {
        &:before {
          background-color: var(--checkbox-background);
          border-color: var(--checkbox-background);
        }

        &:after {
          content: "";
          position: absolute;
          left: 1px;
          top: 13px;
          background: var(--white);
          width: 2px;
          height: 2px;
          box-shadow: 2px 0 0 var(--white), 4px 0 0 var(--white), 4px -2px 0 var(--white), 4px -4px 0 var(--white),
            4px -6px 0 var(--white), 4px 0 0 var(--white);
          transform: rotate(45deg);
        }
      }
    }
    &:focus:not(:checked) ~ .checkbox_label:before {
      border-color: var(--checkbox-border-focus);
      outline: 0;
      box-shadow: 0 0 0 2px rgba(46, 49, 49, 0.25);
    }
    &:focus ~ .checkbox_label {
      &:before {
        border-color: var(--checkbox-border-focus);
        outline: 0;
        box-shadow: 0 0 0 2px rgba(46, 49, 49, 0.25);
      }
    }
    &:disabled {
      + .checkbox_label {
        color: var(--checkbox-textcolor-disabled);
        &:before {
          box-shadow: inset 0 0 0 4px var(--checkbox-background-disabled);
          border-color: var(--checkbox-border-disabled);
          background: var(--checkbox-background-disabled);
          cursor: default;
        }
      }
    }
    + .checkbox_label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}
