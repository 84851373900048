/*-------------------------------------
        Read-only Table
--------------------------------------*/

.readonly {
  border: none !important;

  .rt-resizer {
    display: none !important;
  }

  .rt-th,
  .rt-td {
    padding: 3px 5px !important;
  }

  .rt-thead {
    &.-header,
    label {
      background: transparent;
      box-shadow: none !important;
      color: var(--darkest-grey);
      font-weight: bold !important;
      font-size: 11px;
    }

    label {
      padding: 0;
      margin-top: -1px;
    }
    .rt-tr {
      text-align: left !important;

      .rt-resizable-header-content {
        cursor: pointer;
        span {
          span {
            color: var(--dark-black);
          }
        }
      }
    }

    .rt-th,
    .rt-td {
      padding: 5px;
      border-right: none !important;
    }
  }

  .rt-tbody {
    border: 1px solid var(--dark-grey);
    overflow: hidden;

    .rt-tr-group {
      border-bottom: 1px solid var(--dark-grey);

      .-even {
        background-color: var(--lighter-grey);
      }
    }

    .rt-td {
      height: 25px;
      border-right: none;

      &:last-child {
        text-overflow: initial;
      }

      .btn-outline-secondary {
        margin-left: -1px;
        margin-top: -2px;
      }

      .input-group {
        .col-form-label {
          padding-top: 0;
          padding-bottom: 0;
        }
        .grid-cell {
          &:hover {
            border-bottom: none !important;
          }
        }
      }
    }

    input.grid-cell,
    select.grid-cell {
      border-radius: 0;

      &:focus {
        box-shadow: none;
        border-bottom: none !important;
      }

      &:hover {
        border-bottom: none !important;
      }
    }
  }

  .rt-tfoot {
    box-shadow: none;

    input.grid-cell,
    select.grid-cell {
      &:read-only {
        background-color: transparent;
      }
    }
  }
}

.header-cursor-inital {
  .rt-tr {
    .rt-resizable-header-content {
      cursor: initial !important;
    }
  }
}
