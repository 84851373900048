/*-------------------------------------
        Grids
-------------------------------------*/

.list-group {
  border: 1px solid var(--grid-border-color);
  &-item {
    padding: 0;
    border: none;
    background: transparent;
    margin-bottom: 0;
    border-bottom: 1px solid var(--grid-border-color);

    &:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &:last-child {
      border-bottom: none;
    }

    .input-group {
      margin-left: 1px;
      padding-right: 1px;
      .form-control {
        display: block;
        height: calc(1.563rem + 4px);
        padding: 0.2rem 0.4rem;
        line-height: 1.5;
        background-color: var(--white);
        background-clip: padding-box;
        border: none;
        font-size: 12px;

        &:focus {
          color: var(--text-color);
          background-color: var(--white);
          border-color: var(--border-color-focus);
          outline: 0;
          @include box-shadow(0 0 0 2px rgba(46, 49, 49, 0.25));
        }
        &::placeholder {
          color: var(--placeholder-text);
          opacity: 1; /* Firefox */
          direction: ltr !important;
          text-align: left !important;
        }

        &::-webkit-input-placeholder {
          text-align: left !important;
        }

        &:placeholder-shown {
          direction: ltr !important;
          text-align: left !important;
        }

        &:-ms-input-placeholder,
        &::-ms-input-placeholder {
          /* Internet Explorer 10-11 and Edge */
          color: var(--placeholder-text);
          direction: ltr !important;
          text-align: left !important;
        }
      }

      &-append {
        margin-left: 0;
        margin-top: 2px;
        .btn {
          border: none;
          border-radius: 6px;
          padding: 5px;

          &-outline-secondary {
            &:not(:last-child):not(.dropdown-toggle) {
              border-top-right-radius: 6px !important ;
              border-bottom-right-radius: 6px !important;
            }
          }
        }
      }
    }
  }
}
