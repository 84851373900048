.clickable_label_fix {
  height: 100%;
  margin-top: -5px;

  > div {
    height: 100%;

    label {
      height: 100%;
    }
  }
}

.hypotheek_opties_button {
  > i::before {
    color: var(--black);
  }
}

.text_container {
  h2 {
    padding: 0;
  }
}
