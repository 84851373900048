/*-------------------------------------
        Search Table
--------------------------------------*/

.search-table {
  border: none;
  overflow: auto;

  .rt-table {
    overflow: visible;
  }

  .rt-th,
  .rt-td {
    padding: 3px 5px;
  }

  .rt-thead {
    &.-header,
    label {
      background: transparent;
      box-shadow: none;
      color: var(--darkest-grey);
      font-weight: bold !important;
      font-size: 11px;

      &.regular {
        font-weight: initial !important;
      }
    }

    label {
      padding: 0;
      margin-top: -1px;
    }
    .rt-tr {
      text-align: left;

      .rt-resizable-header-content {
        cursor: pointer;
        color: var(--dark-black);
        span {
          span {
            color: var(--dark-black);
          }
        }
      }
    }

    .rt-th,
    .rt-td {
      padding: 5px;
      border-right: none;
    }
  }

  .rt-tbody {
    overflow: hidden;
    border: 1px solid var(--mid-grey);

    .rt-tr-group {
      border-bottom: 1px solid var(--mid-grey);

      &:hover {
        background: var(--light-grey);
        cursor: pointer;
      }

      .-even {
        background-color: var(--white);
        &:hover {
          background: var(--light-grey);
          cursor: pointer;
        }
      }
    }

    .rt-td {
      height: 25px;
      border-right: none;

      .btn-outline-secondary {
        margin-left: 2px;
        margin-top: -2px;

        &:hover,
        &:focus,
        &:active,
        &:active:hover,
        &:active:focus,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active:focus,
        &:not(:disabled):not(.disabled).active:focus {
          border-radius: 0;
          background-color: transparent;
          border-color: transparent;
        }
      }

      .input-group {
        &:hover {
          border-bottom: none !important;
        }
        .col-form-label {
          padding-top: 0;
          padding-bottom: 0;
        }
        .grid-cell {
          &:hover {
            border-bottom: none !important;
          }
        }
      }
    }

    input.grid-cell,
    select.grid-cell {
      border-radius: 0;

      &:focus {
        box-shadow: none;
        border-bottom: none !important;
      }

      &:hover {
        border-bottom: none !important;
      }
    }
  }

  .rt-tfoot {
    box-shadow: none;

    input.grid-cell,
    select.grid-cell {
      &:read-only {
        background-color: transparent;
      }
    }
  }
}
