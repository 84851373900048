.clickable_label_fix {
    height: 100%;
    margin-top: -5px;
  
    > div {
      height: 100%;
  
      label {
        height: 100%;
      }
    }
  }
  