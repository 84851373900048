/*-------------------------------------
        Drawer
--------------------------------------*/

.drawer {
  &-wrapper {
    @include flex;
    flex-direction: row;
    flex: 1 0 auto;
    position: relative;
    padding-bottom: 3.75rem;

    .drawer {
      &-panel {
        position: relative;
        flex: 0 0 auto;
        width: 250px;
        height: 100%;
        overflow: hidden;
        @include transition(all 0.3s ease);
      }
      &-content {
        position: relative;
        flex: 1 1 auto;
        width: 100%;
        @include transition(all 0.3s ease);
      }

      &-dashboard {
        position: relative;
        width: 150px;
        background: var(--sidemenu-bg-color);
        @include transition(all 0.3s ease);
      }
    }
  }
}

.collapsed {
  width: 3.75rem !important;
}
