.warning {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(110%, 5%);
}

.relative {
  position: relative;
}
