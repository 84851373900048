/*-------------------------------------
         Editable Table
--------------------------------------*/

.editable {
  border: none !important;
  overflow: auto;

  .rt-table {
    overflow: visible;
  }

  .rt-resizer {
    display: none !important;
  }

  .rt-th,
  .rt-td {
    padding: 3px 5px !important;
  }

  .rt-thead {
    &.-header,
    label {
      background: transparent;
      box-shadow: none !important;
      color: var(--darkest-grey);
      font-weight: bold !important;
      font-size: 11px;
    }

    label {
      padding: 0;
      margin-top: -1px;
    }
    .rt-tr {
      text-align: left !important;

      .rt-resizable-header-content {
        cursor: pointer;
        span {
          span {
            color: var(--dark-black);
          }
        }
      }
    }

    .rt-th,
    .rt-td {
      border-right: none !important;
      padding: 5px !important;
    }
  }

  .rt-tbody {
    .rt-tr-group {
      border: 0 !important;

      .input-group {
        width: 100%;
      }

      &:hover {
        background: transparent;

        input {
          background: transparent;
        }

        .input-group,
        select {
          height: 17px !important;
          border-bottom: 1px solid var(--darkest-grey) !important;
        }

        .not-underlined.input-group {
          border-bottom: none !important;
        }

        .input-group {
          .input-group {
            border-bottom: none !important;
          }
        }
      }
    }

    .rt-tr-group {
      &:last-child {
        .rt-tr {
          border-bottom: 1px solid var(--darkest-grey) !important;
        }
        &.selected {
          .rt-tr {
            border-bottom: 1px solid black !important;
          }
        }
      }

      .rt-tr {
        border-top: 1px solid var(--darkest-grey) !important;

        .rt-td {
          border-left: 1px solid var(--darkest-grey) !important;

          &:last-child {
            border-right: 1px solid var(--darkest-grey) !important;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      &.selected {
        .rt-tr {
          background: var(--light-grey);
          &:first-child {
            border-top: 1px solid black !important;
          }

          .rt-td:last-child {
            border-right: 1px solid black !important;
          }

          .rt-td {
            border-left: 1px solid black !important;
          }
        }

        .input-group,
        select {
          border-bottom: 1px solid var(--dark-black) !important;
          background: var(--light-grey);
        }

        .not-underlined.input-group {
          border-bottom: none !important;
        }

        .input-group {
          .input-group {
            border-bottom: none !important;
          }
        }
        input,
        select {
          &.grid-cell {
            background: var(--light-grey);
            &:read-only {
              background-color: var(--light-grey);
            }
          }

          &:hover {
            background: var(--light-grey);
          }
        }
      }

      &.selected + .rt-tr-group {
        .rt-tr {
          border-top: 1px solid var(--black) !important;
        }
      }
    }

    .rt-td {
      height: 25px;

      &:last-child {
        text-overflow: initial;
      }

      .btn-outline-secondary {
        margin-left: -1px;
      }

      .input-group {
        .col-form-label {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }

    input[type="checkbox"] {
      margin-top: -5px;
    }

    input.grid-cell,
    select.grid-cell {
      border-radius: 0 !important;
      &:hover {
        background: transparent;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  .rt-tfoot {
    box-shadow: none;

    input.grid-cell,
    select.grid-cell {
      &:read-only {
        background-color: transparent;
      }
    }
  }
}
