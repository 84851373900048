/* ----------------------------------
            Mixins 
---------------------------------- */

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }

  @-moz-keyframes #{$animation-name} {
    @content;
  }

  @-ms-keyframes #{$animation-name} {
    @content;
  }

  @-o-keyframes #{$animation-name} {
    @content;
  }

  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin box-shadow($shadows...) {
  -webkit-box-shadow: $shadows;
  -moz-box-shadow: $shadows;
  -ms-box-shadow: $shadows;
  box-shadow: $shadows;
}

@mixin flex() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

@mixin font-title($family, $weight, $size: "", $color: "") {
  font-family: $family;
  font-weight: $weight;
  font-size: $size;
  color: $color;
}

@mixin ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/*------------------------------------
       Mixin for media queries 
  --------------------------------------*/

/* Regular phone and tablet breakpoints */
$phone: "(max-width: 480px)";
$tablet-portrait: "(max-width: 767px)";
$tablet-landscape: "(min-width: 768px) and (max-width: 979px)";
$large-desktop: "(min-width: 992px)";
$non-retina: "(-webkit-max-device-pixel-ratio: 1)";
$retina: "(min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2),(webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5),(min-resolution: 144dpi),(min-resolution: 1.5dppx)";

/* Bootstrap breakpoint */
$xxl-screens: "(min-width: 2300px)";
$extra-large-screens: "(min-width: 1200px) and (max-width: 1268px)";
$large-screens-card-padding: "(min-width: 993px) and (max-width: 1285px)";
$large-screens: "(min-width: 993px) and (max-width: 1080px)";
$medium-screens: "(max-width: 992px)";
$small-screens: "(max-width: 767px)";
$extra-small-screens: "(max-width: 576px)";
$no-more-resize: "(max-width: 992px)";

/* Specific phone breakpoints */

$ipad-portrait: "(min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait)";
$iphone5-portrait: "(min-device-width: 320px)  and (max-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)";
$iphone5-landscape: "(min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape)";
$iphone6-portrait: "(min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)";
$iphone6-landscape: "(min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape)";
$iphone6Plus-portrait: (
  "(min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait)"
);
$iphone6Plus-landscape: (
  "(min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape)"
);
$galaxys5-portrait: (
  "(device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
);
$galaxys5-landscape: (
  "(device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
);

@mixin breakpoint($media) {
  @media only screen and #{$media} {
    @content;
  }
}

/* How to use */

/* body {
        background-color: rgba(#1abc9c, .5);
        @include breakpoint($medium-screens) {
          background-color: rgba(#2ecc71, .5);
        }
        @include breakpoint($small-screens) {
          background-color: rgba(#3498db, .5);
        }
        @include breakpoint($extra-small-screens) {
          background-color: rgba(#9b59b6, .5);
        }  
      }
  */

/*------------------------------------------------------------------
       Custom Mixin for Bootstrap Sizing with Bootstrap breakpoints
  --------------------------------------------------------------------*/

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }
      }
    }
  }
}
