.navigation-bar {
    background-color: var(--white);
    height: 3.75rem;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    padding: 1rem;
  
    display: flex;
    flex-wrap: wrap;
  
    &__header {
      display: flex;
      align-items: center;
      width: 25%;
    }
  
    &__content {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 75%;
    }
  
    &__title {
      font-weight: 700;
      font-size: var(--font-brand);
      margin-bottom: 0;
    }
  
    &__applicants {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: .5rem;
      border-right: 1px solid var(--border-color-normal);
      height: 100%;
    }
  
    &__applicant {
      margin-right: 1rem;
    }
  
    &__icon {
      margin-right: .5rem;
    }
  
    &__dossier {
      border-right: 1px solid var(--border-color-normal);
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  
    &__item {    
      & > .btn-link {
        height: 2rem;
        font-weight: 700;
      }
    }
  
    &__actions {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 1rem;
    }
  }