/*------------------------------------------------------------------------------------------------- 
    Card
------------------------------------------------------------------------------------------------- */

.card-adviesbox {
  min-width: 500px;
  //height: 100%; Apart per flex-row en -column aangepast in base.scss (chrome version 77 bug)
  margin: .1rem;
  margin-bottom: .5rem;
  border: none;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  &:hover {
    text-decoration: none;
  }

  .card-header {
    display: flex;
    background-color: var(--white);
    padding: 1rem;
    position: relative;
    justify-content: space-between;

    .card-header-buttons {
      max-height: 30px;
    }

    h2 {
      font-weight: 400;
      font-size: 22px;
      margin-bottom: 0;
    }
  }

  .card-body {
    padding: 0rem;
    padding-bottom: 2rem;

    .content-body {
      padding: 0;
      border-bottom: none;

      h2 {
        &:first-child {
          margin: 0 5px 4px;
        }

        margin: 30px 5px 4px;
      }
    }
  }

  .button-container {
    margin: 20px 0;
  }
}

.master-detail-card {
  .all-cards-same-size {
    flex: 1 1 100%;
  }

  .card-adviesbox {
    min-width: 500px;
  }

  @include breakpoint($xxl-screens) {
    width: 100%;
  }
}

.hypotheek-totalen-card {
  .all-cards-same-size {
    flex: 1 1 354px;
  }

  .card-adviesbox {
    min-width: 354px;
    height: 100%;

    @include breakpoint($medium-screens) {
      min-width: 500px;
    }
    @include breakpoint($small-screens) {
      min-width: 500px;
    }
  }
}

.fullscreen-card {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 1px;
  height: 1px;
  z-index: 9999;
  overflow: scroll;
  transform: translate(-50%, -50%);
  border: 2vw solid rgba(0, 0, 0, 0.6);
  -webkit-background-clip: padding-box;
  box-sizing: content-box;
  background-clip: padding-box;
  @include animation(fullscreen 0.3s ease-out forwards);
}

@include keyframes(fullscreen) {
  to {
    width: 98%;
    height: 98%;
  }
}

.clickable-cross {
  cursor: pointer;
}
