/* please do not change font-face src order (should be: woff / tff / svg) */
@font-face {
  font-family: "AdviesboxIcons";
  src: url("/fonts/AdviesboxIcons.woff?v7018x") format("woff"),
    url("/fonts/AdviesboxIcons.ttf?v7018x") format("truetype"),
    url("/fonts/AdviesboxIcons.svg?v7018x#AdviesboxIcons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
