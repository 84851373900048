/*-----------------------------------
        Validation
-----------------------------------*/

.is-invalid {
  border-color: var(--validation-red-border);
  background-color: var(--validation-bg-red);

  &:focus {
    background-color: var(--white);
    border-color: var(--validation-red-border);
    outline: 0;
    box-shadow: 0 0 0 2px var(--validation-bg-red);
  }
}

.child-is-invalid {
  input {
    border-color: var(--validation-red-border);
    background-color: var(--validation-bg-red);

    &:focus {
      background-color: var(--white);
      border-color: var(--validation-red-border);
      outline: 0;
      box-shadow: 0 0 0 2px var(--validation-bg-red);
    }
  }
}

.foutmelding {
  width: 100%;
  height: .75rem;
  color: var(--validation-red-text);
  font-size: 11px;
  line-height: 13px;
}

.is-ongeldig {
  border-color: var(--validation-red-border);
  background-color: var(--validation-bg-red);
  animation: colorchange 2.5s 1;
  -webkit-animation: colorchange 2.5s 1;
  animation-fill-mode: forwards;

  -webkit-animation-fill-mode: forwards;
}

@keyframes colorchange {
  0% {
    border-color: var(--validation-red-border);
    background-color: var(--validation-bg-red);
  }
  50% {
    border-color: var(--validation-red-border);
    background-color: var(--validation-bg-red);
  }
  100% {
    border: 1px solid var(--border-color-normal);
    background-color: var(--white);
  }
}

.warning {
  border-color: var(--warning-yellow-border);
  background-color: var(--warning-bg-yellow);
}

/* Toast melding */

.toast {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 27px;
  border: 1px solid var(--validation-red-border);

  &.show {
    display: inline-flex !important;
  }
  .toast-body {
    padding: 0.5rem;
    background: var(--validation-bg-red);
    color: var(--validation-red-text);
  }
}

.ReactTable {
  .error {
    position: relative;
    background: var(--validation-bg-red);
    border-right: none !important;

    input.grid-cell,
    select.grid-cell {
      background: var(--validation-bg-red) !important;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid var(--validation-red-border);
      border-bottom: 7px solid transparent;
    }

    .foutmelding {
      position: absolute;
      background: var(--validation-bg-red);
      padding: 5px;
      color: var(--white);
      height: 50px;
      width: 250px;
      overflow: visible;

      &:before {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent var(--validation-bg-red) transparent;
      }
    }
  }
}
