.content_wrapper {
  min-height: calc(100vh - 60px);
}

.content_wrapper .content {
  width: 100%;
}

.dashboard-content {
  background: #EFEFEF;
  z-index: 0;
  width: 270px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.sidebar_dashboard {
  &__title {
    text-align: center;
    padding-top: 1rem;
  }

  &__btn {
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 2rem;
  }
}

.sidebar_dashboard {
  &.collapsed {
    width: 100px;

    .scenario-block {
      margin-left: 10px;
    }
    .scenario-berekenen-text {
      display: none;
    }

    .dashboard-content {
      padding: 10px 0;

      .scenario-block {
        padding-bottom: 0;

        .head-text {
          text-align: center;
        }

        .form-group {
          display: none;
        }
      }
    }
  }

  .head-text {
    padding-left: 4px;
  }
}

.dashboard {
  transition: all 0.3s ease;
  position: sticky;
  top: 60px;

  &-wrapper {
    display: flex;
    min-height: 100vh;
  }

  .menu-minimize {
    @include flex();
    flex-grow: 1;
    -webkit-box-flex: 1;
    align-items: stretch;
    height: 30px;
    padding: 10px 17px;
    cursor: pointer;

    .drawer {
      margin-top: -5px;
    }

    &-text {
      @include flex();
      @include flex-direction(row);
      align-items: center;
      flex-grow: 1;
      margin: -5px 8px;
      color: var(--sidemenu-text-color);
      font-size: 12px;
      font-style: italic;
      font-weight: normal;
      line-height: 15px;
      opacity: 0.5;
      @include ellipsis();
    }

    .icon {
      font-size: 10px;
    }
  }

  .scenario-berekenen {
    @include flex();
    flex-grow: 1;
    -webkit-box-flex: 1;
    align-items: stretch;
    height: 30px;
    padding: 10px 17px;
    align-items: center;
    width: 175px;

    &-text {
      @include flex();
      @include flex-direction(row);
      align-items: center;
      flex-grow: 1;
      margin: -5px 8px;
      color: var(--white);
      font-size: 12px;
      font-weight: normal;
      width: 200px;
      @include ellipsis();
    }
  }

  &-content {
    padding: 10px 17px;
    height: 100%;

    .scenario-block {
      padding-bottom: 0.5rem;

      .form-row > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
      }

      .head-text {
        color: var(--gray);
        cursor: pointer;

        &:hover {
          font-weight: 800;
        }
      }
    }
  }
}
